import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Factoraje from '../views/Factoraje.vue'
import FactorajeProv from '../views/FactorajeProv.vue'
import Fideicomiso from '../views/FideicomisoCredito.vue'
import FideicomisoDes from '../views/FideicomisoDes.vue'
import Fin from '../views/Fin.vue'
import Noapto from '../views/NoApto.vue'
Vue.use(VueRouter)

const routes = [
  { 
    path: '/',
    component: Index
  },
  { 
    path: '/solicitud/completado',
    component: Fin
  },
  {
    path:"/solicitud/lo-sentimos",
    component: Noapto
  },
  { 
    path: '/solicita-tu-credito',
    component: Index
  },
  { 
    path: '/solicita-tu-factoraje-financiero',
    component: Factoraje
  },
  { 
    path: '/solicita-tu-factorare-pago-a-proveedores',
    component: FactorajeProv
  },
  { 
    path: '/solicita-tu-fideicomiso-desarrollo-inmobiliario',
    component: FideicomisoDes
  },
  { 
    path: '/solicita-tu-fideicomiso-de-garantia-de-credito',
    component: Fideicomiso
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
 } 
})

export default router
